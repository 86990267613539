import { extendTheme } from "@chakra-ui/react";

const overrides = {
  //styles,
  styles: {
    global: {
      // styles for the `body`
      body: {        
        color: "#8d8e90"        
      },
      
    },
  },
  // Other foundational style overrides go here
  //borders,
  fonts: {
    body: "Montserrat,Verdana,'Helvetica Neue',Arial,'Liberation Sans',FreeSans,sans-serif",
    heading: "Montserrat,Verdana,'Helvetica Neue',Arial,'Liberation Sans',FreeSans,sans-serif"        
  },
    
  
}

export default extendTheme(overrides)


